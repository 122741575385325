* {
  box-sizing: border-box;
  color: #000;
  font-family: Raleway, sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  min-height: 100vh;
  background: #fff;
}

a {
  border-bottom: 1px dotted ;
  text-decoration: none;
}

a:hover {
  border-bottom: 1px solid ;
}

section {
  padding: 3rem;
}

.lKb-_a_page {
  min-height: 100vh;
  min-width: 90rem;
  background: #fff;
  display: flex;
}

.lKb-_a_header {
  color: #fff;
  height: 100vh;
  min-width: 22.5rem;
  width: calc(50vw - 22.5rem);
  background: #000;
  flex-direction: column;
  display: flex;
  position: sticky;
  top: 0;
}

.lKb-_a_header > div {
  height: 100vh;
  width: 22.5rem;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-end;
  padding-bottom: 3rem;
  display: flex;
}

.lKb-_a_logo {
  width: 100%;
  border-bottom: none;
  text-decoration: none;
}

.lKb-_a_logo:hover {
  border-bottom: none;
}

.lKb-_a_header h1 {
  height: 2.5rem;
  width: 100%;
  background: url("Long-w.a18b5e35.svg") right 2rem top 0 / contain no-repeat;
  margin: 0;
}

.lKb-_a_main {
  min-height: 100vh;
  min-width: 67.5rem;
  width: calc(50vw + 22.5rem);
  flex-direction: column;
  display: flex;
}

.lKb-_a_main > div {
  min-height: 100vh;
  width: 67.5rem;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
  display: flex;
}

.lKb-_a_footer {
  align-self: flex-end;
  padding: 2rem 3rem;
}

.lKb-_a_footer .lKb-_a_rsIcon > img {
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: sub;
  background: #fff;
  display: inline-block;
}

.lKb-_a_footer .lKb-_a_rsIcon {
  border-bottom: none;
}

.lKb-_a_menuIcon, .lKb-_a_menuBtn {
  display: none;
}

.lKb-_a_menu {
  text-align: right;
  max-height: none;
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
  transition: all .2s ease-out;
  overflow: hidden;
}

.lKb-_a_menu a, .lKb-_a_menu a:hover {
  color: #fff;
  white-space: nowrap;
  border-bottom: none;
}

.lKb-_a_menu li {
  padding-right: 2rem;
  line-height: 3;
}

.lKb-_a_menu li:hover {
  background: #fff;
}

.lKb-_a_menu li:hover a {
  color: #000;
}

@media (max-width: 90rem) {
  .lKb-_a_page {
    min-width: 52.125rem;
    flex-direction: column;
  }

  .lKb-_a_header {
    height: 4.5rem;
    min-width: auto;
    width: 100%;
    flex-direction: row;
  }

  .lKb-_a_header > div {
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
    align-items: center;
    padding-bottom: 0;
  }

  .lKb-_a_header h1 {
    height: 2.5rem;
    width: 50%;
    background: url("Short-w.8f61ccd5.svg") 3rem 0 / contain no-repeat;
    margin: 0;
  }

  .lKb-_a_main {
    min-height: calc(100vh - 4.5rem);
    min-width: auto;
    width: 100%;
  }

  .lKb-_a_main > div {
    width: 100%;
    min-height: calc(100vh - 4.5rem);
  }

  .lKb-_a_footer {
    align-self: flex-start;
  }

  .lKb-_a_menuIcon {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 1.75rem 3rem 1.75rem 1.5rem;
    display: inline-block;
    position: relative;
  }

  .lKb-_a_navicon {
    height: .3125rem;
    width: 2rem;
    background: #fff;
    transition: background .2s ease-out;
    display: block;
    position: relative;
  }

  .lKb-_a_navicon:before, .lKb-_a_navicon:after {
    content: "";
    height: 100%;
    width: 100%;
    background: #fff;
    transition: all .2s ease-out;
    display: block;
    position: absolute;
  }

  .lKb-_a_navicon:before {
    top: .75rem;
  }

  .lKb-_a_navicon:after {
    top: -.725rem;
  }

  .lKb-_a_menu {
    max-height: 0;
    background: #000;
    margin-top: 0;
    font-size: 1.5rem;
    transition: all .2s ease-out;
    position: absolute;
    top: 4.5rem;
    right: 0;
  }

  .lKb-_a_menuBtn:checked ~ .lKb-_a_menu {
    max-height: 13.5rem;
    padding-bottom: 15rem;
    transition: all .2s ease-out;
  }

  .lKb-_a_menu li {
    padding-left: 2rem;
  }

  .lKb-_a_menu a {
    color: #fff;
    border-bottom: 1px dotted ;
  }

  .lKb-_a_menu li:hover {
    background: #000;
  }

  .lKb-_a_menu li:hover a {
    color: #fff;
    border-bottom: 1px solid ;
  }

  .lKb-_a_menuBtn:checked ~ .lKb-_a_menuIcon .lKb-_a_navicon {
    background: none;
  }

  .lKb-_a_menuBtn:checked ~ .lKb-_a_menuIcon .lKb-_a_navicon:before {
    transform: rotate(-45deg);
  }

  .lKb-_a_menuBtn:checked ~ .lKb-_a_menuIcon .lKb-_a_navicon:after {
    transform: rotate(45deg);
  }

  .lKb-_a_menuBtn:checked ~ .lKb-_a_menuIcon:not(.lKb-_a_steps) .lKb-_a_navicon:before, .lKb-_a_menuBtn:checked ~ .lKb-_a_menuIcon:not(.lKb-_a_steps) .lKb-_a_navicon:after {
    top: 0;
  }
}

@media (max-width: 52.125rem) {
  .lKb-_a_page {
    min-width: auto;
    flex-direction: column;
  }

  .lKb-_a_header {
    height: 4rem;
  }

  .lKb-_a_header h1 {
    background: url("Icon-w.6a7fb669.svg") 1rem 0 / contain no-repeat;
  }

  .lKb-_a_menuIcon {
    padding: 1.75rem 1rem;
  }

  .lKb-_a_menu {
    top: 4rem;
  }

  .lKb-_a_main, .lKb-_a_main > div {
    min-height: calc(100vh - 4rem);
  }

  .lKb-_a_footer {
    align-self: center;
    padding: 2rem 1rem;
  }

  .lKb-_a_footer > .lKb-_a_line {
    text-align: center;
    display: block;
  }
}

@keyframes TFP7qq_borderBlink {
  from, to {
    border-color: #0000;
  }

  50% {
    border-color: #000;
  }
}

.TFP7qq_container {
  border-right: 2px solid #000;
  animation: TFP7qq_borderBlink 1.5s step-end infinite;
  display: inline-block;
}

.vRzsIW_home {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 3rem;
  display: flex;
}

.vRzsIW_home p {
  text-align: right;
  font-size: 4rem;
  font-weight: 300;
}

.vRzsIW_home p > strong {
  font-weight: 700;
}

.vRzsIW_line {
  display: block;
}

.vRzsIW_logos {
  justify-content: center;
  align-items: center;
  gap: 10rem;
  margin-top: 3rem;
  display: flex;
}

.vRzsIW_logos a, .vRzsIW_logos a:hover {
  border-bottom: none;
}

.vRzsIW_logos img {
  height: 7.5rem;
  width: auto;
}

@media (max-width: 90rem) {
  .vRzsIW_home {
    align-items: flex-start;
    padding: 0 3rem;
  }

  .vRzsIW_home p {
    text-align: left;
  }

  .vRzsIW_logos {
    gap: 7.5rem;
  }
}

@media (max-width: 52.125rem) {
  .vRzsIW_home {
    align-items: center;
    padding: 0 1rem;
  }

  .vRzsIW_home p {
    text-align: center;
    font-size: 8vw;
  }

  .vRzsIW_logos {
    flex-direction: column;
    gap: 5rem;
    margin-bottom: 3rem;
  }

  .vRzsIW_logos img {
    max-height: 7.5rem;
    height: auto;
    width: 100%;
  }
}

.VXuP0G_statement {
  font-size: 150%;
  font-style: italic;
  font-weight: 300;
}

.oUvUma_badge {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  display: inline;
}

._1AYajW_project {
  margin-top: 3rem;
}

/*# sourceMappingURL=index.949ce8c2.css.map */
