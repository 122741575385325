* {
  box-sizing: border-box;
  color: #000;
  font-family: 'Raleway', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  background: #fff;
  min-height: 100vh;
}

a {
  text-decoration: none;
  border-bottom: 1px dotted;
}

a:hover {
  border-bottom: 1px solid;
}

section {
  padding: 3rem;
}
